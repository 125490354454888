




































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getBacklog } from "@/request/home";
import { GetCenter } from "@/request/common";
@Component({})
export default class Name extends Vue {
  private data: any = [];
  private qrsrc: any = "";
  private centers: any = [];
  private getData() {
    getBacklog(this).then((res:any) => {
      this.data = res;
    });
  }
  private goKongzhi(item: any) {
    if (item.data.length === 0) {
      return;
    }
    this.centers.forEach((ele: any) => {
      if (ele["项目名称"] === item["项目名称"]) {
        localStorage.setItem("center", JSON.stringify(ele));
        this.$router.push("/main/storehouse/control/list");
      }
    });
  }
  /**
   * @description 获得专病数据库列表
   */
  private getCenterList() {
    GetCenter(this).then((data: any) => {
      this.centers = data;
    });
  }
  private mounted() {
    this.qrsrc = window.qrsrc;
    this.getCenterList();
    this.getData();
  }
}
