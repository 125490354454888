



































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getHomeData } from "@/request/home";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  private qrsrc: any = "";
  private data: any = [];
  private showMore: any = false;
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getHomeData(this, loading).then((res: any) => {
      this.data = res;
    });
  }
  mounted() {
    this.qrsrc = window.qrsrc;
    this.getData();
  }
}
