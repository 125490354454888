


































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import HomeShuguang from "../components/home-shuguang.vue";
import HomeMinzuyi from "../components/home-mzy.vue";
import HomeYunnanminzuyi from "../components/home-ynmzy.vue";
import HomeNingbo from "../components/home-ningbo.vue";
import Hunan from "../components/home-hunan.vue";
import Gjmy from "../components/home-gjmy.vue";
import HomeDefault from "../components/home-default.vue";
@Component({
  components: {
    MainHeader,
    HomeShuguang,
    HomeMinzuyi,
    HomeNingbo,
    HomeYunnanminzuyi,
    Hunan,
    HomeDefault,
    Gjmy
  },
})
export default class Name extends Vue {
  private get configuration() {
    return this.$store.state.configuration;
  }
}
