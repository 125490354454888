import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 获取待办事项
 * @param vCom Vue组件
 */
const getBacklog = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/backlog").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close()
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close()
            }
            reject();
        });
    });
};
/**
 * @description 获取首页项目数据
 * @param vCom Vue组件
 */
const getHomeData = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/app/projects").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close()
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close()
            }
            reject();
        });
    });
};
export {
    getBacklog,
    getHomeData
};
